import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import * as React from 'react';
import {HeroStage} from '../comps/HeroStage';
import '../comps/ImageContentBlock.scss';
import './main.scss';


// markup
const IndexPage = () =>
{

  const img = './../images/plane.jpg';
  const img2 = './../images/Dubai.jpg';
  const img3 = './../images/mountain2.jpg';
  const img4 = './../images/dream.jpg';
  const img5 = './../images/map.jpg';

  return (
    <>
      <HeroStage />
      <div className={'block'}>
        <div className={'images'}>

          <div className={'imageCluster'}>
            <div className={'imageBox box1'}>
              <StaticImage src={img} alt={''} style={{width: '100%'}} className={'image img1'} />

            </div>
            <div className={'imageBox box2'}>
              <StaticImage src={img2} alt={''} style={{width: '100%'}} className={'image img1'} />
            </div>
          </div>

        </div>
        <div className={'content'}>
          <div className={'contentBox'}>
            <h1>Vì sao chọn chúng tôi?</h1>
            <h2>Chất lượng Reisebüro Đức, ngôn ngữ và văn hóa Việt!</h2>
            <p>Giá trị về thời gian: Để chúng tôi, những chuyên gia nhiều kinh nghiệm trong lĩnh vực kì nghỉ, lựa chọn, thiết kế, và tổ chức cho bạn một kì nghỉ theo đúng ước nguyện của bạn, thì bạn sẽ tiết kiệm được thời gian quý báu của bạn để làm
               những việc giá trị hơn, bạn không cần phải mày mò gì cả ngoài việc chia sẻ với chúng tôi mong muốn của bạn...và nghĩ xem nên mặc gì tận hưởng kì nghỉ ra sao.</p>
            <p>Có một kì nghỉ KHÔNG ÂU LO: Từ lúc lên kế hoạch cho đến khi nằm dài sưởi nắng đâu đó, bạn sẽ không cần phải lo nghĩ gì cả, chúng tôi sẽ đảm bảo kì nghỉ của bạn chỉ có niềm vui, và nếu hi hữu có điều gì xảy ra, bạn cũng không cần phải
               cuống cuồng lên gọi đủ số hotline, bạn chỉ cần bấm máy duy nhất một số thôi, chúng tôi sẽ ở đó để lo cho bạn.</p>
            <p>Được hưởng lợi từ kinh nghiệm dày dạn của những người am hiểu và đam mê du lịch, đã tự mình mày mò khảo sát và lựa chọn tỉ mỉ từng điểm đến, từng khu nghỉ, từng nhà hàng và thậm chí là từng góc chụp hình.</p>
          </div>
        </div>
      </div>
      <div className={'block right'}>
        <div className={'images'}>
          <div className={'imageCluster cols'}>
            <div className={'imageBox box1'}>
              <StaticImage src={img3} alt={''} style={{width: '100%'}} className={'image img1'} />

            </div>
            <div className={'imageBox box2'}>
              <StaticImage src={img4} alt={''} style={{width: '100%'}} className={'image img1'} />
            </div>
          </div>
        </div>
        <div className={'content'}>
          <div className={'contentBox'}>
            <h1>Đi đâu</h1>
            <p>Đi khắp thế gian! Từ châu Âu cổ kính, đến châu Phi hoang dã, từ thiên đường Maldives, đến Ai Cập huyền bí, từ Caribean xanh ngắt, đến châu Á rực rỡ sắc màu, nước Mỹ rộng lớn mê hoặc, hay Thổ Nhĩ Kì thú vị, hay nghỉ dưỡng cao cấp ở Việt
               Nam,
               chúng tôi sẽ thiết kế kì nghỉ tuyệt vời nhất cho bạn và người thân!</p>
          </div>
        </div>
      </div>
      <div className={'block'}>
        <div className={'images'}>

          <div className={'imageCluster'} style={{gridTemplateRows: '1fr'}}>
            <StaticImage src={img5} alt={''} style={{height: '100%'}} className={'image img1'} />

          </div>

        </div>
        <div className={'content'}>
          <div className={'contentBox'}>
            <h1>Contact</h1>
            <h2>Huyen Nguyen</h2>
            <p>
              Oberaicher Weg 17, 70771<br />
              Leinfelden-Echterdingen<br /><br />
              +49 (176) 22 64 20 46<br />
              <a href={'mailto:huyen@nguyen.reisen'}>huyen@nguyen.reisen</a>
            </p>
            <Link to={'/Imprint'}>Impressum</Link>
          </div>
        </div>
      </div>
    </>
  );
};


export default IndexPage;
